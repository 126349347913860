/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import 'app/shared/colorsConst.scss';

@font-face {
    font-family: 'Labora';
    src: url('./app/fonts/Labora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'oblong';
    src: url('./app/fonts/oblong.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
}

:host ::ng-deep {
    .p-inputtext {
        padding: 0.5rem 0.75rem !important;
    }

    .p-dialog-content{
        
       
        scrollbar-width: none;
    }


    .p-button {
        height: 35px;
        border-radius: 6px;
        padding: 0.75rem 1.25rem;
        font-size: 1rem;
    }

    .p-button-label {
        font-weight: 400;
    }

    .p-dropdown-panel .p-dropdown-items {
        padding-left: 0 !important;
    }

    .p-dropdown-items {
        padding-left: 0 !important;
    }

    .p-multiselect {
        width: 100%;
    }
    
    .p-datatable-wrapper {
        border-radius: 6px;
    }

    .tabMenu {
        .p-menuitem-link {
            text-decoration: none;
            color: #6b7280;
            font-size: 0.85rem;
        }

        .p-tabmenuitem.p-highlight .p-menuitem-link {
            color: $aahaar-primary-color !important;
            border-color: $aahaar-primary-color !important;
            font-size: 0.85rem;
        }
    }
}

ul {
    padding-left: 0 !important;
}

th {
    font-size: 15px;
    color: black;
}